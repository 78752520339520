<template>
    <div class="text-[11px] leading-tight pb-2 text-start text-[#A6B2D3] font-righteous">
        <p>
            Fill in your e-mail address and we will send you instructions on how to reset your
            password via e-mail.
        </p>
        <p class="pt-2">Contact us via <span class="text-[#FB9A2C] underline">Support</span> if you need further help.
        </p>
    </div>
    <p class="text-text-modal text-[13px] font-bold text-start pb-1">
        Email
    </p>


    <TextBoxVue :icon="'at'" :type="'email'" :green="greenTick" :placeHolder="'Enter your Email'"
        @updateField="handleUpdateUser" @updateData="handleErrorUser" />

    <div class="text-start">
        <div class="flex pt-6 pb-4">
            <ButtonBox :text="'Reset Password'" :icon="'chevron-right'" />
        </div>
    </div>
</template>

<script setup>
import TextBoxVue from '@/components/form/TextBox.vue'
import ButtonBox from '@/components/form/ButtonBox.vue'
import { ref } from 'vue'

const error_user = ref(false)
const user = ref('')
const greenTick = ref(false)
const handleUpdateUser = e => {
    user.value = e.email.value
}

const handleErrorUser = e => {
    error_user.value = e
    if (e == false) {
        greenTick.value = true
    } else greenTick.value = false
}
</script>

<style scoped>
/* Custom checkbox tick color */
input[type='checkbox']:checked {
    background-color: #131931; /* Green background when checked */
    border-color: #1e284a; /* Green border when checked */
    box-shadow: 0px 12px 16px 0px #0000000f;
}
input[type='checkbox']:disabled:focus {
    box-shadow: none;
    border-color: #1e284a; /* Green border when checked */
}

input[type='checkbox']:focus {
    box-shadow: none !important;
}
.custom-checkbox input[type='checkbox'] {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #131931;
    border: 2px solid #1e284a;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.custom-checkbox input[type='checkbox']:checked {
    border-color: #1e284a; /* Green border when checked */
}

.custom-checkbox input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 39%;
    left: 47%;
    transform: translate(-50%, -50%) rotate(44deg) scaleX(1);
    width: 6px;
    height: 11px;
    border: solid #00ff94;
    box-shadow: 0px 0px 0px 0px #00ff9473;
    border-width: 0 3px 3px 0;
}

.custom-checkbox label {
    font-size: 11px;
    padding-left: 5px;
}
/* Hide default checkbox appearance and style the new one */
input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 1.5rem;
    accent-color: #4caf50; /* Change tick color */

    height: 1.5rem;
    background-color: #131931; /* Green background when checked */
    border-radius: 0.375rem; /* Tailwind rounded */
    border-color: #4caf50; /* Green border when checked */
    transition: background-color 0.2s, border-color 0.2s;
    position: relative;
}
</style>
