<template>
    <div style="min-width: 296px" class="px-3 relative h-full">
        <div class="flex flex-col h-full justify-between">
            <div class="flex-grow">
                <router-link to="/">
                    <div class="relative flex justify-center">
                        <img src="../assets/elipsis.png" class="object-cover absolute" />
                        <img class="pt-1" style="width:160px" src="../assets/the-game-hub.png" />
                    </div>
                </router-link>
                <!-- Button Group -->
                <div class="grid font-righteous text-xs text-white grid-cols-2 grid-rows-2 gap-2">
                    <button class="side-button relative rounded-md tournament">
                        <div class="absolute bottom-0 left-0">
                            <img src="../assets/tournaments.png" />
                        </div>

                        <div class="flex w-full pl-5 items-center justify-center">Tournaments</div>
                    </button>
                    <button class="side-button relative rounded-md loyalty">
                        <div class="absolute bottom-0 left-0">
                            <img src="../assets/medlio.png" />
                        </div>
                    </button>

                    <button class="side-button relative rounded-md vip">
                        <div class="absolute bottom-0 left-0">
                            <img src="../assets/vip.png" />
                        </div>
                    </button>
                    <!-- <button class="side-button relative rounded-md promotions">
                        <div
                            class="overflow-hidden flex align-middle p-0 bg-gradient-to-tr from-promotions-2 to-promotions-1 h-9 rounded shadow"
                        >
                            <div class="absolute bottom-0 left-0">
                                <img src="../assets/promos.png" />
                            </div>
                        </div>
                    </button> -->
                    <button disabled class="side-button disabled:opacity-85 relative rounded-md bonus">
                        <div class="absolute bottom-0 left-0">
                            <img src="../assets/bonus-wheel.png" />
                        </div>
                    </button>
                    <!-- <button
                        disabled
                        class="side-button disabled:opacity-85 relative rounded-md missions"
                    >
                        <div class="absolute bottom-0 left-0">
                            <img src="../assets/missions.png" />
                        </div>
                    </button> -->
                </div>
                <!-- End of Button Group -->
                <div class="pt-5">
                    <ul class="flex flex-col gap-1 mx-auto">
                        <div class="w-full">
                            <DropDownVue :icon="'card'" :text="'Casino'" @updateModal="setModal" :items_list="[
                                'Top Games',
                                'New',
                                'Slots',
                                'Instant Win',
                                'Bonus Buy',
                                'Shooting',
                                'Scratch'
                            ]" />
                        </div>
                        <div class="w-full">
                            <DropDownVue :icon="'berry'" :text="'Live Casino'" @updateModal="setModal" :items_list="[
                                'Top Games',
                                'Roulette',
                                'Blackjack',
                                'Game Shows',
                                'Poker',
                                'Baccarat'
                            ]" />
                        </div>
                        <div
                            class=" py-3 bg-dropdown-1 rounded-lg border-border-dropdown-1 border-2 w-full flex justify-start pl-4">

                            <span :class="$route.path == '/' ? 'text-[#C0BAE4]' : 'text-button-side'
    " class="flex font-bold text-xs font-proxima"><img
                                    style="filter:invert(28%) sepia(12%) saturate(2405%) hue-rotate(193deg) brightness(96%) contrast(86%);"
                                    class="pr-2" width="26" height="26" src="../assets/football.png" alt=""><span
                                    class="flex w-full" style="white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /">Live
                                    Sports</span></span>
                            <div class="flex ml-auto justify-end"><img
                                    style="filter:invert(28%) sepia(12%) saturate(2405%) hue-rotate(193deg) brightness(96%) contrast(86%);"
                                    class="pr-2" width="26" height="26" src="../assets/share.png" alt=""></div>

                        </div>

                        <!-- <div class="w-full">
                            <DropDownVue
                                icon="icon-game"
                                @updateModal="setModal"
                                text="eSports"
                                :items_list="['FPS', 'MOBA', 'Sports']"
                            />
                        </div> -->
                    </ul>
                </div>
                <div class="w-full h-px mt-3 bg-border-dropdown-1"></div>
                <div class="flex justify-start pt-5 px-3 items-center">
                    <router-link to="/">
                        <div class="flex items-center">
                            <img :class="$route.path == '/' ? 'filter-beje' : ''" class="max-w-3.5 max-h-3.5 flex"
                                src="../assets/home.png" />
                            <div class="pl-2">
                                <span :class="$route.path == '/' ? 'text-[#C0BAE4]' : 'text-button-side'
                                    " class="font-bold text-xs font-proxima">Home</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="flex justify-start pt-5 px-3 items-center text-button-side">
                    <img class="max-w-3.5 max-h-3.5 flex" src="../assets/lottery.png" />
                    <div class="pl-2">
                        <span class="font-bold text-xs font-proxima">Lottery</span>
                    </div>
                </div>


                <div class="flex justify-start pt-5 px-3 items-center">
                    <router-link to="/refer">
                        <div class="flex items-center">
                            <img :class="$route.path == '/refer' ? 'filter-blue' : ''" class="max-w-3.5 max-h-3.5 flex"
                                src="../assets/refer.png" />
                            <div class="pl-2">
                                <span :class="$route.path == '/refer'
                                    ? 'text-[#C0BAE4]'
                                    : 'text-button-side'
                                    " class="font-bold text-xs font-proxima">Refer & Earn</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="flex justify-start pt-5 px-3 items-center">
                    <router-link to="/faqs">
                        <div class="flex items-center">
                            <img :class="$route.path == '/faqs' ? 'filter-green' : ''" class="max-w-3.5 max-h-3.5 flex"
                                src="../assets/faq.png" />
                            <div class="pl-2" :class="$route.path == '/faqs' ? 'text-[#C0BAE4]' : 'text-button-side'
                                ">
                                <span class="font-bold text-xs font-proxima">FAQ</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="w-full h-px mt-4 bg-border-dropdown-1 text-button-side"></div>
                <div class="flex justify-start pt-5 px-3 items-center">
                    <a target="_blank" class="flex items-center" href="https://t.me/TheGameHubSupportBot">
                        <img class="max-w-3.5 max-h-3.5 flex" src="../assets/union.png" />
                        <div class="pl-2">
                            <span class="font-bold text-xs font-proxima text-button-side">Support</span>
                        </div>
                    </a>
                </div>
                <!-- <div class="w-full mt-3 mb-6 flex-grow">
                    <ul>
                        <DropDownVue icon="eng" text="ENG" />
                    </ul>
                </div> -->
            </div>
            <!-- Footer side bar -->

            <div class="flex flex-col flex-end gap-1 py-6 px-3">
                <div style="font-size: 14px" class="flex justify-start font-righteous text-button-side">
                    <span>Join our Community</span>
                </div>
                <div class="flex justify-start">
                    <button class="rounded-md w-9 h-9 mr-2 border-2 border-border-dropdown-1 bg-dropdown-1">
                        <div class="flex justify-center">
                            <a href="https://t.me/TheGameHubXYZ" target="_blank"><img
                                    src="../assets/telegram.png" /></a>
                        </div>
                    </button><button class="rounded-md w-9 h-9 mr-2 border-2 border-border-dropdown-1 bg-dropdown-1">
                        <div class="flex justify-center">
                            <a href="https://x.com/TheGameHub_io" target="_blank">
                                <img style="height: 15px" class="filter" src="../assets/X.svg" />
                            </a>
                        </div>
                    </button>
                    <button class="rounded-md mr-2 w-9 h-9 border-2 border-border-dropdown-1 bg-dropdown-1">
                        <div class="flex justify-center">
                            <a href="https://discord.gg/HVWtQ4WpTT" target="_blank">
                                <img style="width: 23px" class="filter" src="../assets/discord.svg" />
                            </a>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <!-- End Footer side bar -->
    </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useDashboardStore } from '@/store/dashboardStore'
import { auth, supabase } from '@/main';

import { ref, computed, onMounted } from 'vue'

import DropDownVue from './DropDownVue.vue'
const dashboardStore = useDashboardStore()
// Define props if needed
const item_ref = ref(null)
const modalState = computed(() => dashboardStore.modal)

const header = ref('')
const text_ = ref('')

onMounted(() => {
    dashboardStore.getMostPopularList('', '', 'dashboard')
})

const setModal = async (item, text) => {
    header.value = item
    text_.value = text
    dashboardStore.setSideCat(text)
    dashboardStore.setHeader(item)
    console.log("text", text, item)

    if (text == 'Casino') {
        let query = supabase
            .from('qt_games')
            .select('*').ilike('category', `%CASINO%`).limit(100)
        console.log("casino", query)
        if (item == 'Slots') {
            query = query.ilike('category', `%SLOT%`)
            dashboardStore.setSelectedGT('SLOT')

        }
        if (item == 'Top Games') {
            const ids = dashboardStore.popularGames.map(obj => obj.id);
            console.log("QUERY", ids)
            query = query.in('id', ids)
            dashboardStore.setGameList('top_games')
            dashboardStore.setSelectedGT("TopGames")

        }
        if (item == 'New') {
            console.log("Not supposed here")
            query = query.order('release_date', { ascending: false }).limit(100)
            dashboardStore.setSelectedGT('All')

        }
        if (item == 'Instant Win') {
            query = query.ilike('category', `%INSTANTWIN%`)

            dashboardStore.setSelectedGT('INSTANTWIN')
            console.log("SELECTEGT", dashboardStore.selectedGT)
        }
        if (item == 'Shooting') {
            dashboardStore.setSelectedGT('SHOOTING')

            query = query.ilike('category', `%SHOOTING%`)
        }
        if (item == 'Scratch') {
            dashboardStore.setSelectedGT('SCRATCH')

            query = query.ilike('category', `%SCRATCH%`)
        }

        // Execute the query
        const { data, error } = await query;

        if (error) {
            console.error('Error fetching games:', error);
            return;
        }

        console.log('Filtered results:', data);
        dashboardStore.setGameList2(data)
        dashboardStore.setSearchList(data); // Update the search list
        // dashboardStore
        //     .getGameList({ size: 40, gameTypes: 'LIVECASINO' }, '', 'sidebar')
        //     .then(() => {
        //         if (item == 'Top Games') {
        //             console.log('Top Games')
        //         }
        //         if (item == 'Roulette' && dashboardStore.sideMenuList.items) {
        //             let val = null
        //             val =
        //                 dashboardStore.sideMenuList &&
        //                 dashboardStore.sideMenuList.items.filter(item =>
        //                     item.category.includes('ROULETTE')
        //                 )
        //             dashboardStore.setSideMenuList(val)
        //         }

        //         if (item == 'Blackjack' && dashboardStore.sideMenuList.items) {
        //             let val = null
        //             val =
        //                 dashboardStore.sideMenuList &&
        //                 dashboardStore.sideMenuList.items.filter(item =>
        //                     item.category.includes('BLACKJACK')
        //                 )
        //             dashboardStore.setFilterCategory('BLACKJACK')
        //             dashboardStore.setSideMenuList(val)
        //         }
        //         if (item == 'Baccarat' && dashboardStore.sideMenuList.items) {
        //             let val = null
        //             val =
        //                 dashboardStore.sideMenuList &&
        //                 dashboardStore.sideMenuList.items.filter(item =>
        //                     item.category.includes('BACCARAT')
        //                 )
        //             dashboardStore.setFilterCategory('BACCARAT')
        //             dashboardStore.setSideMenuList(val)
        //         }
        //         if (item == 'Game Shows' && dashboardStore.sideMenuList.items) {
        //             let val = null
        //             val =
        //                 dashboardStore.sideMenuList &&
        //                 dashboardStore.sideMenuList.items.filter(item =>
        //                     item.category.includes('GAMESHOWS')
        //                 )
        //             dashboardStore.setFilterCategory('GAMESHOWS')
        //             dashboardStore.setSideMenuList(val)
        //         }
        //         if (item == 'Poker' && dashboardStore.sideMenuList.items) {
        //             let val = null
        //             val =
        //                 dashboardStore.sideMenuList &&
        //                 dashboardStore.sideMenuList.items.filter(item =>
        //                     item.category.includes('POKER')
        //                 )
        //             dashboardStore.setFilterCategory('POKER')

        //             dashboardStore.setSideMenuList(val)
        //         }
        //         dashboardStore.setHeader(item)
        //     }
        dashboardStore.setModalOpen(true, 'search-modal')
    }

    if (text == 'Live Casino') {
        let query = supabase
            .from('qt_games')
            .select('*').ilike('category', `%LIVECASINO%`).limit(100)

        if (item == 'Top Games') {
            console.log("TOPGAMES")
        }
        if (item == 'Roulette') {
            dashboardStore.setSelectedGT('ROULETTE')

            query = query.ilike('category', `%ROULETTE%`)
        }
        if (item == 'Blackjack') {
            dashboardStore.setSelectedGT('BLACKJACK')
            console.log("SELECTEGT", dashboardStore.selectedGT)
            query = query.ilike('category', `%BLACKJACK%`)
        }
        if (item == 'Sports') {
            query = query.ilike(('category', `%SPORTS%`))
        }
        if (item == 'Game Shows') {
            dashboardStore.setSelectedGT('GAMESHOWS')

            query = query.ilike('category', `%GAMESHOWS%`)
        }
        if (item == 'Poker') {
            dashboardStore.setSelectedGT('POKER')

            query = query.ilike('category', `%POKER%`)
        }
        if (item == 'Baccarat') {
            dashboardStore.setSelectedGT('BACCARAT')

            query = query.ilike('category', `%BACCARAT%`)
        }
        // Execute the query
        const { data, error } = await query;

        if (error) {
            console.error('Error fetching games:', error);
            return;
        }

        console.log('Filtered results:', data);
        dashboardStore.setGameList2(data)
        dashboardStore.setSearchList(data);
        // Update the search list
        // dashboardStore.getGameList({ size: 40, gameTypes: 'ESPORTS' }, '', 'sidebar').then(() => {
        //     if (item == 'FPS' && dashboardStore.sideMenuList.items) {
        //         let val = null
        //         val =
        //             dashboardStore.sideMenuList &&
        //             dashboardStore.sideMenuList.items.filter(item => item.category.includes('FPS'))
        //         dashboardStore.setFilterCategory(item)

        //         dashboardStore.setSideMenuList(val)
        //         dashboardStore.setHeader(item)
        //     }
        //     if (item == 'MOBA' && dashboardStore.sideMenuList.items) {
        //         let val = null
        //         val =
        //             dashboardStore.sideMenuList &&
        //             dashboardStore.sideMenuList.items.filter(item => item.category.includes('MOBA'))
        //         dashboardStore.setFilterCategory(item)

        //         dashboardStore.setSideMenuList(val)
        //         dashboardStore.setHeader(item)
        //     }
        //     if (item == 'Sports' && dashboardStore.sideMenuList.items) {
        //         let val = null
        //         val =
        //             dashboardStore.sideMenuList &&
        //             dashboardStore.sideMenuList.items.filter(item =>
        //                 item.category.includes('SPORTS')
        //             )
        //         dashboardStore.setFilterCategory(item)

        //         dashboardStore.setSideMenuList(val)
        //         dashboardStore.setHeader(item)
        //     }
        // })
    }
    dashboardStore.setModalOpen(true, 'search-modal')

}
</script>
<style scoped>
.tournament {
    background: linear-gradient(to top right, #7b5635, #2b2f4e);
}
.filter {
    filter: invert(22%) sepia(17%) saturate(1590%) hue-rotate(190deg) brightness(102%) contrast(94%);
}

.side-button::before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 4px;
}
.tournament::before {
    padding: 0 0px 0 20px;
    content: 'Tournaments';
    background: linear-gradient(to top right, #4e3133 50%, #121b3e);
}

.loyalty {
    background: linear-gradient(to top right, #853d32, #2b2f4e);
}
.filter-blue {
    filter: brightness(0) saturate(100%) invert(18%) sepia(83%) saturate(5018%) hue-rotate(220deg)
        brightness(105%) contrast(104%);
}
.loyalty::before {
    content: 'Loyalty';
    background: linear-gradient(to top right, #4e3133 50%, #121b3e);
}

.filter-yellow {
    filter: brightness(0) saturate(100%) invert(96%) sepia(74%) saturate(6342%) hue-rotate(331deg)
        brightness(101%) contrast(101%);
}

.missions {
    background: linear-gradient(to top right, #50357b 50%, #2b2f4e);
}

.missions::before {
    content: 'Missions';
    background: linear-gradient(to top right, #460e68 50%, #121b3e);
}

.missions::before:disabled {
    content: 'Missions';
    background: linear-gradient(to top right, #616161 50%, #373838);
}

.vip {
    background: linear-gradient(to top right, #1d58ca 50%, #2b2f4e);
}

.vip::before {
    content: 'Vip Club';
    background: linear-gradient(to top right, #042d66 50%, #1a154d);
}

.bonus::before {
    padding: 0 0px 0 20px;
    content: 'Bonus Wheel';
    background: linear-gradient(to top right, #0d4869 50%, #121b3e);
}

.bonus {
    background: linear-gradient(to top right, #1c66ab 50%, #2b2f4e);
}

.promotions::before {
    padding: 0 0px 0 20px;
    content: 'Promotions';
    background: linear-gradient(to top right, #4e3133 50%, #121b3e);
}

.promotions {
    background: linear-gradient(to top right, #853d32 50%, #2b2f4e);
}
.side-button {
    height: 40px;
}

.filter-prom {
    filter: invert(22%) sepia(13%) saturate(2404%) hue-rotate(190deg) brightness(99%) contrast(89%);
}
.filter-red {
    filter: brightness(0) saturate(100%) invert(20%) sepia(81%) saturate(5906%) hue-rotate(355deg)
        brightness(96%) contrast(125%);
}

.filter-beje {
    filter: invert(84%) sepia(12%) saturate(1160%) hue-rotate(346deg) brightness(86%) contrast(97%);
}

.filter-green {
    filter: brightness(0) saturate(100%) invert(72%) sepia(98%) saturate(453%) hue-rotate(86deg)
        brightness(100%) contrast(83%);
}
.list {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 10px; /* Space between the columns */
}

.list li {
    margin: 5px 0;
}
</style>
