<template>
    <p class="text-text-modal text-[13px] font-bold text-start pb-1">
        Email
    </p>
    <!-- <p v-if="error_user == true" class="text-red-500 text-[13px] font-bold text-start pb-1">
        Please enter a valid email
    </p> -->

    <TextBoxVue :index="1" :toggleAction="actionTrigger" ref="tx1" :required="true" :icon="'at'" :type="'email'"
        :green="greenTick" :placeHolder="'Enter your Email'" @updateField="handleUpdateUser"
        @updateData="handleErrorUser" />
    <p class="text-text-modal pt-2 text-[13px] font-bold text-start pb-1">User Name</p>
    <!-- <p v-if="error_disp == true" class="text-red-500 pt-2 text-[13px] font-bold text-start pb-1">
        Please enter a valid username
    </p> -->
    <TextBoxVue :index="2" :toggleAction="actionTrigger" ref="tx2" :required="true" :icon="'at'" :type="'text'"
        :placeHolder="'Enter your user name'" @updateField="handleUpdateDisplayName" v-model="displayName"
        @updateData="handleErrorDisplay" @blur="handleUpdateDisplayName" />
    <p class="text-text-modal pt-2 text-[13px] font-bold text-start pb-1">Password</p>
    <!-- <p v-if="error_pass == true" class="text-red-500 text-[13px] font-bold text-start pt-2 pb-1"> Please enter a valid
        password
    </p> -->
    <TextBoxVue :index="3" :toggleAction="actionTrigger" ref="tx3" :login="false" :icon="'pallete'"
        @updateData="handleErrorPass" @updateField="handleUpdatePass" :placeHolder="'Enter your Password'"
        :endIcon="'eye-off'" :type="'password'" />
    <!-- <p class="text-text-modal pt-2 text-[13px] font-bold text-start pb-1">Your Country</p>
    <TextBoxVue :login="false" :type="'text'" :icon="'flag'" :endIcon="'chevron-down'" :isDropDown="true"
        :isCountry="true" @updateField="handleChangeCountry" :itemsList="[
            ' Afghanistan', 'Albania', 'Germany', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Saudi Arabia', 'Algeria'
            , 'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bangladesh', 'Barbados'
            , 'Bahrain', 'Belgium', 'Belize', 'Benin', 'Belarus', 'Burma', 'Bolivia', 'Bosnia and Herzegovina'
            , 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Bhutan', 'Cape Verde'
            , 'Cambodia', 'Cameroon', 'Canada', 'Qatar', 'Chad', 'Chile', 'China', 'Cyprus', 'Vatican City'
            , 'Colombia', 'Comoros', 'North Korea', 'South Korea', 'Ivory Coast', 'Costa Rica', 'Croatia', 'Cuba'
            , 'Denmark', 'Dominica', 'Ecuador', 'Egypt', 'El Salvador', 'United Arab Emirates', 'Eritrea', 'Slovakia'
            , 'Slovenia', 'Spain', 'United States', 'Estonia', 'Ethiopia', 'Philippines', 'Finland', 'Fiji'
            , 'France', 'Gabon', 'Gambia', 'Georgia', 'Ghana', 'Grenada', 'Greece', 'Guatemala', 'Guyana', 'Guinea'
            , 'Equatorial Guinea', 'Guinea-Bissau', 'Haiti', 'Honduras', 'Hungary', 'India', 'Indonesia', 'Iraq'
            , 'Iran', 'Ireland', 'Iceland', 'Marshall Islands', 'Solomon Islands', 'Israel', 'Italy', 'Jamaica'
            , 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kyrgyzstan', 'Kiribati', 'Kuwait', 'Laos', 'Lesotho'
            , 'Latvia', 'Lebanon', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'North Macedonia'
            , 'Madagascar', 'Malaysia', 'Malawi', 'Maldives', 'Mali', 'Malta', 'Morocco', 'Mauritius', 'Mauritania'
            , 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Mozambique', 'Namibia'
            , 'Nauru', 'Nepal', 'Nicaragua', 'Niger', 'Nigeria', 'Norway', 'New Zealand', 'Oman', 'Netherlands'
            , 'Pakistan', 'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Poland', 'Portugal'
            , 'Democratic Republic of the Congo', 'Dominican Republic', 'Rwanda', 'Romania', 'Russia', 'Samoa'
            , 'Saint Kitts and Nevis', 'San Marino', 'Saint Vincent and the Grenadines', 'Saint Lucia'
            , 'Sao Tome and Principe', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Syria'
            , 'Somalia', 'Sri Lanka', 'Eswatini', 'South Africa', 'Sudan', 'South Sudan', 'Sweden', 'Switzerland'
            , 'Suriname', 'Thailand', 'Tanzania', 'Tajikistan', 'East Timor', 'Togo', 'Tonga', 'Trinidad and Tobago'
            , 'Tunisia', 'Turkmenistan', 'Turkey', 'Tuvalu', 'Ukraine', 'Uganda', 'Uruguay', 'Uzbekistan', 'Vanuatu'
            , 'Venezuela', 'Vietnam', 'Yemen', 'Djibouti', 'Zambia', 'Zimbabwe']" /> -->
    <div class="text-start">
        <div class="custom-checkbox font-righteous max-w-[246px] flex flex-col pt-5">
            <div class="flex items-center pb-3">
                <input type="checkbox" id="scales" v-model="isChecked"
                    class="focus:ring-[#ff000000] focus:ring-2 focus:outline-none focus:border-[#ff000000]"
                    name="scales" />

                <label for="scales" class="text-[11px] ml-2">
                    <div class="text-text-modal leading-tight">
                        I am 18 years old and I accept the
                        <span class="text-[#A6B2D3]">Privacy Policy</span> and
                        <span class="text-[#A6B2D3]">Terms and Conditions</span>
                    </div>
                </label>
            </div>
            <div class="flex pb-4 items-center">
                <input type="checkbox" id="scales"
                    class="focus:ring-[#ff000000] pr-2 focus:ring-2 focus:outline-none focus:border-[#ff000000]"
                    name="scales" />

                <label for="scales" class="text-[11px] ml-2">Receive Promos</label>
            </div>

        </div>
        <div class="flex pt-6 pb-4">
            <ButtonBox :disabled="isChecked == false" @click="handleRegister(displayName)" :text="'Register'"
                :icon="'chevron-right'" />
        </div>
        <div class="flex pb-6 text-[11px] font-righteous justify-center">
            Already have an account?
            <button @click="setModalLogin" class="pl-1 text-[#FB9A2C]">Login</button>
        </div>
    </div>
</template>

<script setup>
import TextBoxVue from '@/components/form/TextBox.vue'
import ButtonBox from '@/components/form/ButtonBox.vue'
import { exampleRegister, fetchUserBalance } from '@/main'
import { exampleLogin } from '@/main'
import { getAuth, signOut, updateProfile } from "firebase/auth";



import { ref } from 'vue'
import { useAuthStore } from '@/store/authStore'
import { useDashboardStore } from '@/store/dashboardStore'
import { auth } from '@/main';
import axios from 'axios'

const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const error_user = ref(false)
const error_pass = ref(false)
const error_disp = ref(false)
const displayName = ref('')
const user = ref('')
const actionTrigger = ref(false)
const password = ref('')
const country = ref('')
const greenTick = ref(false)

const isChecked = ref(false)
const emit = defineEmits(['openLoginModal'])

const setModal = (val, name) => {
    dashboardStore.setModalOpen(val, name)
}

const handleRegister = (text) => {
    console.log("TESTTEREGISTER1", text)

    actionTrigger.value = !actionTrigger.value;
    console.log("TESTTEREGISTER1", text)

    if (isChecked.value == true) {
        if (user.value == '')
            error_user.value = true
        if (password.value == '')
            error_pass.value = true
        if (displayName.value == '')
            error_disp.value = true
        console.log("TESTTEREGISTER", text)
        exampleRegister(user.value, password.value).then(() => {
            const request = indexedDB.open('firebaseLocalStorageDb', 1)
            console.log("names", user.value)
            request.onerror = function (event) {
                console.log('Error opening database:', event.target.errorCode)
            }

            request.onsuccess = function (event) {
                const db = event.target.result
                console.log('Database opened successfully')
                console.log(db)
                accessData(db)
                fetchUserBalance()
                // After opening the database, you can now access its data.
            }
            sessionStorage.setItem('displayName', displayName.value)
            console.log("DISPLAYNAME", displayName.value)
            updateProfile(auth.currentUser, {
                displayName: text, photoURL: "https://example.com/jane-q-user/profile.jpg"
            }).then(() => {

                console.log("HERE")
                exampleLogin(user.value, password.value)
                // Profile updated!
                // ...
            }).catch((error) => {
                // An error occurred
                // ...
            });
            if (!error_user.value && !error_pass.value && !error_disp.value)
                setModal(false, 'register-modal')
        })
    } else {
        console.log("er")
    }

}

const accessData = (db) => {
    const transaction = db.transaction(['firebaseLocalStorage'], 'readonly');
    const objectStore = transaction.objectStore('firebaseLocalStorage');

    // Fetching all data from the object store
    const getAllRequest = objectStore.getAll();

    getAllRequest.onsuccess = function (event) {
        const data = event.target.result;
        console.log(data)
        if (data.length != 0) {
            sessionStorage.setItem("uid", data[0].value.uid)
            sessionStorage.setItem("accessToken", data[0].value.stsTokenManager.accessToken)
            sessionStorage.setItem("refreshToken", data[0].value.stsTokenManager.refreshToken)
        }


        console.log("Data retrieved successfully:", data);
        if (data.length > 0) {
            authStore.login()
        } else {
            sessionStorage.clear()
            signOut(auth).then(() => {
                // Sign-out successful.
            }).catch((error) => {
                // An error happened.
            });
            authStore.logout()
        }
        // You can now use or display the data.
    };

    getAllRequest.onerror = function (event) {
        console.log("Error retrieving data:", event.target.errorCode);
    };
}
const handleUpdateUser = e => {
    user.value = e.email.value
}

const handleChangeCountry = e => {
    country.value = e.text
}

const setModalLogin = () => {
    emit('openLoginModal')
}
const handleUpdatePass = e => {
    console.log("pass", e.password.value)
    password.value = e.password.value
}

const handleUpdateDisplayName = (e) => {
    console.log("updateDisplay", e.text)
    if (e.text != undefined)
        displayName.value = e.text
    console.log("DISPLAYNAME", displayName.value)
}
const handleErrorUser = e => {
    error_user.value = e
    if (e == false) {
        greenTick.value = true
    } else greenTick.value = false
}

const handleErrorPass = e => {
    error_pass.value = e
}

const handleErrorDisplay = e => {
    console.log(e)
    error_disp.value = e
}
</script>

<style scoped>
/* Custom checkbox tick color */
input[type='checkbox']:checked {
    background-color: #131931; /* Green background when checked */
    border-color: #1e284a; /* Green border when checked */
    box-shadow: 0px 12px 16px 0px #0000000f;
}
input[type='checkbox']:disabled:focus {
    box-shadow: none;
    border-color: #1e284a; /* Green border when checked */
}

input[type='checkbox']:focus {
    box-shadow: none !important;
}
.custom-checkbox input[type='checkbox'] {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #131931;
    border: 2px solid #1e284a;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.custom-checkbox input[type='checkbox']:checked {
    border-color: #1e284a; /* Green border when checked */
}

.custom-checkbox input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 39%;
    left: 47%;
    transform: translate(-50%, -50%) rotate(44deg) scaleX(1);
    width: 6px;
    height: 11px;
    border: solid #00ff94;
    box-shadow: 0px 0px 0px 0px #00ff9473;
    border-width: 0 3px 3px 0;
}

.custom-checkbox label {
    font-size: 11px;
    padding-left: 5px;
}
/* Hide default checkbox appearance and style the new one */
input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 1.5rem;
    accent-color: #4caf50; /* Change tick color */

    height: 1.5rem;
    background-color: #131931; /* Green background when checked */
    border-radius: 0.375rem; /* Tailwind rounded */
    border-color: #4caf50; /* Green border when checked */
    transition: background-color 0.2s, border-color 0.2s;
    position: relative;
}
</style>
