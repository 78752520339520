<template>
    <!-- component -->
    <div class="fixed z-50 inset-0 flex items-center justify-center overflow-hidden" v-if="isOpen">
        <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-bg-modal opacity-75"></div>
        </div>

        <div class="gradient-box  modal-border rounded-xl text-left overflow-hidden shadow-xl transform transition-all">
            <div class="inner-box modal-inner relative min-h-14 ">
                <button @click="setModal(false, props.name)">
                    <img class="absolute right-5 h-4 top-5 border-[#23232448] rounded-full" src="../assets/cross.svg"
                        alt="" />
                </button>

                <div style="width: 550px; " class="px-8 pt-9 flex flex-col flex-start pb-7">
                    <p class="text-lg leading-6 pb-3 font-proxima font-bold text-2xl text-white text-start">
                        {{ dashboardStore.modal ? dashboardStore.modal.header : '' }}
                    </p>
                    <div style="width:500px" v-if="props.name == 'register-modal'">
                        <RegisterModal @openLoginModal="handleLoginModal" />
                    </div>
                    <div style="width:500px" v-if="props.name == 'login-modal'">
                        <LoginModal @openForgetModal="handleForgetModal" @openRegisterModal="handleRegisterModal" />
                    </div>
                    <div v-if="props.name == 'forget-modal'">
                        <ForgetModal />
                    </div>
                    <div style=" height:425px;" v-if="props.name == 'profile-modal'">
                        <ProfileModal />
                    </div>
                    <div v-if="props.name == 'deposit-modal'">
                        <DepositModal />
                    </div>
                    <div v-if="props.name == 'payment-modal'">
                        <PaymentModal />
                    </div>
                    <div style="max-height:589px" class="overflow-y-auto no-scrollbar"
                        v-if="props.name == 'search-modal'">
                        <SearchModal />
                    </div>
                    <div v-if="props.name == 'game-modal'">
                        <GameModal :header="dashboardStore.modal.header" :text_="text_" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDashboardStore } from '@/store/dashboardStore'
import SearchModal from '@/components/modals/SearchModal.vue'
import RegisterModal from '@/components/modals/RegisterModal.vue'
import LoginModal from '@/components/modals/LoginModal.vue'
import ForgetModal from '@/components/modals/ForgetModal.vue'
import GameModal from './modals/GameModal.vue'
import DepositModal from '@/components/modals/DepositModal.vue'

import PaymentModal from './modals/PaymentModal.vue'
import { ref, watch } from 'vue'
import ProfileModal from '@/components/modals/ProfileModal.vue'
const props = defineProps(['isOpen', 'name', 'header', 'sidebar', 'navbar', 'text'])
const dashboardStore = useDashboardStore()
const header_val = ref(props.header)
const setModal = (val, name) => {
    if (val == false) {
        dashboardStore.setLoading(false)
        dashboardStore.setGameList([])
        console.log("loading")
    }
    dashboardStore.setErrors("")
    dashboardStore.setModalOpen(val, name)
    console.log("errprs", dashboardStore.errors)
}

const handleForgetModal = () => {
    dashboardStore.setModalOpen(false, 'login-modal')
    dashboardStore.setModalOpen(true, 'forget-modal')
    dashboardStore.setHeader('Forget Password')

    header_val.value = 'Forget Password'
}

const handleLoginModal = () => {
    dashboardStore.setModalOpen(false, 'register-modal')
    dashboardStore.setModalOpen(true, 'login-modal')
    dashboardStore.setHeader('Login')
    header_val.value = 'Login'
}

const handleRegisterModal = () => {
    dashboardStore.setModalOpen(false, 'login-modal')
    dashboardStore.setModalOpen(true, 'register-modal')
    header_val.value = 'Sign Up'
    dashboardStore.setHeader('Sign Up')

}

watch(() => props.header, (newheader) => {
    header_val.value = newheader;
});

</script>

<style>
.gradient-box {
    border-radius: 20px;
    /* Adjust this value to change the border radius */
    padding: 1px;
    /* Adjust to match the border width */
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-box {
    width: 100%;
    height: 100%;
    border-radius: 11px;
    /* Slightly less than the outer box to fit inside */
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    text-align: center;
}

/* Rectangle 18664 */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.modal-border {
    border: 0.5px solid;

    background: linear-gradient(180deg, #2b2f4e 0%, #7b5635 100%);
}

.modal-inner {
    background: linear-gradient(135deg, #403135, #12153e, #121b3e, #12153e, #403135);
}
</style>
