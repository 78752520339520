<template>
    <div class="w-full" @keypress="handlePress">
        <p class="text-text-modal text-[13px] font-bold text-start pb-1">
            Email
        </p>
        <!-- <p v-if="error_user == true" class="text-red-500 text-[13px] font-bold text-start pb-1">
            Please enter a valid Email
        </p> -->

        <TextBoxVue :index="1" :toggleAction="actionTrigger" :icon="'at'" :type="'email'" :green="greenTick"
            :placeHolder="'Enter your Email'" v-model="user" @updateField="handleUpdateUser"
            @updateData="handleErrorUser" :required="true" />
        <p class="text-text-modal pt-2 text-[13px] font-bold text-start pb-1">
            Password</p>

        <!-- <p v-if="dashboardStore.errors.length
            != 0 && dashboardStore.errors[0] != '' || error_pass == true"
            class="text-red-500 pt-2 text-[13px] font-bold text-start pb-1">
            Please enter a valid Password
        </p> -->
        <TextBoxVue :index="2" :toggleAction="actionTrigger" :icon="'pallete'" :required="true"
            @updateField="handleUpdatePass" v-model="password" @updateData="handleErrorPass"
            :placeHolder="'Enter your Password'" :endIcon="'eye-off'" :type="'password'" :login="true" />

        <p @click="setModalForget"
            class="text-text-modal  cursor-pointer underline pt-2 text-[11px] font-bold text-start pb-1">
            Forgot your password?
        </p>

        <div class="text-start">
            <div class="flex pt-6 pb-4">
                <ButtonBox @keypress="handlePress" @click="handleLogin" :text="'Login'" :icon="'chevron-right'" />
            </div>
            <div class="flex pb-6 text-[11px] font-righteous justify-center">
                Don't have an account
                <button @click="setModalRegister" class="pl-1 text-[#FB9A2C] underline">
                    Sign Up
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import TextBoxVue from '@/components/form/TextBox.vue'
import ButtonBox from '@/components/form/ButtonBox.vue'
import { useDashboardStore } from '@/store/dashboardStore'
import { exampleLogin, fetchUserBalance } from '@/main'
import { ref } from 'vue'
import { useAuthStore } from '@/store/authStore'
import { getAuth, signOut } from "firebase/auth";
import { auth } from '@/main';


const actionTrigger = ref(false)

const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const setModal = (val, name) => {
    dashboardStore.setErrors("")
    console.log("state", dashboardStore.errors)
    dashboardStore.setModalOpen(val, name)
}
const error_user = ref(false)
const error_pass = ref(false)
const user = ref('')
const password = ref('')
const greenTick = ref(false)
const emit = defineEmits(['openForgetModal'])
const handleUpdateUser = e => {
    console.log("updateUser", e.email.value)
    user.value = e.email.value
}

console.log("elements", document.querySelectorAll('input, [tabindex]'))

const setModalForget = () => {
    emit('openForgetModal')
}
const handleUpdatePass = e => {
    console.log("pass", password.value)
    password.value = e.password.value
}

const handlePress = (event) => {
    console.log(event)
    if (event.key == 'Enter') {
        event.target.blur()
        actionTrigger.value = !actionTrigger.value;
        event.preventDefault()
        console.log("LOGIN", user.value, password.value)
        handleLogin()
    }
}

const setModalRegister = () => {
    emit('openRegisterModal')
}

const handleLogin = () => {
    actionTrigger.value = !actionTrigger.value;

    console.log("clicked", user.value, password.value)
    if (user.value == '')
        error_user.value = true
    if (password.value == '')
        error_pass.value = true

    console.log("USER", user.value, password.value)
    const val = exampleLogin(user.value, password.value).then(() => {
        const request = indexedDB.open('firebaseLocalStorageDb', 1)

        request.onerror = function (event) {
            console.log('Error opening database:', event.target.errorCode)
        }

        request.onsuccess = function (event) {
            const db = event.target.result
            console.log(db)
            console.log('Database opened successfully')
            accessData(db)
            fetchUserBalance()
            console.log("successsss")
            // After opening the database, you can now access its data.
        }
        console.log(error_user.value, error_pass.value)
        if (error_user.value == false && error_pass.value == false && (dashboardStore.errors.length == 0 || dashboardStore.errors[0] == ''))
            setModal(false, 'login-modal')
        console.log("ERROS")
    }).catch(error => {
        alert(error)
        console.log("error2", error);
        console.log(error_user.value, error_pass.value)
        if (error_user.value == false && error_pass.value == false && (dashboardStore.errors.length == 0 || dashboardStore.errors[0] == ''))
            setModal(false, 'login-modal')

    })

}

const accessData = (db) => {
    const transaction = db.transaction(['firebaseLocalStorage'], 'readonly');
    const objectStore = transaction.objectStore('firebaseLocalStorage');

    // Fetching all data from the object store
    const getAllRequest = objectStore.getAll();

    getAllRequest.onsuccess = function (event) {
        console.log(event)
        const data = event.target.result;
        console.log("DATA", data)
        console.log(data)
        if (data.length != 0) {
            sessionStorage.setItem("uid", data[0].value.uid)
            sessionStorage.setItem("accessToken", data[0].value.stsTokenManager.accessToken)
            sessionStorage.setItem("refreshToken", data[0].value.stsTokenManager.refreshToken)
        }


        console.log("Data retrieved successfully:", data);
        if (data.length > 0) {
            authStore.login()
        } else {
            sessionStorage.clear()
            signOut(auth).then(() => {
                // Sign-out successful.
            }).catch((error) => {
                // An error happened.
            });
            authStore.logout()
        }
        // You can now use or display the data.
    };

    getAllRequest.onerror = function (event) {
        console.log("Error retrieving data:", event.target.errorCode);
    };
}


const handleErrorUser = e => {
    console.log("error", e)
    error_user.value = e
    if (e == false) {
        greenTick.value = true
    } else greenTick.value = false
}

const handleErrorPass = e => {
    console.log(e)
    error_pass.value = e
}
</script>

<style scoped>
/* Custom checkbox tick color */
input[type='checkbox']:checked {
    background-color: #131931; /* Green background when checked */
    border-color: #1e284a; /* Green border when checked */
    box-shadow: 0px 12px 16px 0px #0000000f;
}
input[type='checkbox']:disabled:focus {
    box-shadow: none;
    border-color: #1e284a; /* Green border when checked */
}

input[type='checkbox']:focus {
    box-shadow: none !important;
}
.custom-checkbox input[type='checkbox'] {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #131931;
    border: 2px solid #1e284a;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.custom-checkbox input[type='checkbox']:checked {
    border-color: #1e284a; /* Green border when checked */
}

.custom-checkbox input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 39%;
    left: 47%;
    transform: translate(-50%, -50%) rotate(44deg) scaleX(1);
    width: 6px;
    height: 11px;
    border: solid #00ff94;
    box-shadow: 0px 0px 0px 0px #00ff9473;
    border-width: 0 3px 3px 0;
}

.custom-checkbox label {
    font-size: 11px;
    padding-left: 5px;
}
/* Hide default checkbox appearance and style the new one */
input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 1.5rem;
    accent-color: #4caf50; /* Change tick color */

    height: 1.5rem;
    background-color: #131931; /* Green background when checked */
    border-radius: 0.375rem; /* Tailwind rounded */
    border-color: #4caf50; /* Green border when checked */
    transition: background-color 0.2s, border-color 0.2s;
    position: relative;
}
</style>
