<template>
    <div>
        <div :class="props.profile == true ? 'profile-container' : 'input-container'" v-if="!props.isDropDown">
            <div class="input-icon" v-if="props.icon">
                <img class="w-4" :src="require(`../../assets/${icon}.png`)" alt="Icon" />
                <span v-if="noSpace == null" class="pl-2 text-text-modal">|</span>
                <!-- Adjust size as needed -->
            </div>
            <!--In case show password false and true-->
            <input :tabindex="index" v-if="props.type != 'password'"
                class="rounded-lg py-[8px] bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2"
                :type="props.type" @blur="handleBlur" @input="handleChange" :placeholder="placeHolder" :class="[
                    error == true ? 'text-red-500 ' : '',
                    green == true ? 'border-green-500' : null,
                    noSpace == true ? 'pl-[36px] pr-[26px]' : 'pl-[50px] pr-[26px]'
                ]" ref="text_ref" v-model="text" :required="props.required" />
            <input :tabindex="index" :required="props.required" v-if="props.type == 'password' && showPassword == false"
                class="rounded-lg py-[8px] pl-[50px] pr-[26px] bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2"
                :type="props.type" @blur="handleBlur" :placeholder="placeHolder" :class="[
                    props.login == false ? error == true ? 'text-red-500 ' : '' : null,
                    props.login == false ? green == true ? 'border-green-500' : null : null
                ]" ref="text_ref" v-model="text" />
            <input :tabindex="index" v-if="props.type == 'password' && showPassword == true"
                class="rounded-lg py-[8px] pl-[50px] pr-[26px] bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2"
                :type="'text'" @blur="handleBlur" :placeholder="placeHolder" :class="[
                    props.login == false ? error == true ? 'text-red-500 ' : '' : null,
                    props.login == false ? green == true ? 'border-green-500' : null : nullj
                ]" ref="text_ref" v-model="text" :required="props.required" />
            <!--In case show password false and true-->

            <div class="end-icon">
                <img v-if="green == true" src="../../assets/tick-green.svg" alt="Icon" width="24" height="24" />
                <button @click="handleShowPassword">
                    <img v-if="endIcon" :src="require(`../../assets/${endIcon}.svg`)" alt="Icon" width="20"
                        height="20" />
                </button>
                <!-- Adjust size as needed -->
            </div>
        </div>
        <span class="flex pt-1 flex-start text-red-500 text-bold text-[11px] text-start" v-if="errorMessage != ''">{{
            errorMessage }}</span>

        <details v-if="props.isDropDown == true && props.isCountry == false"
            class="group flex items-center justify-between gap-2 p-2 min-h-[44px] font-medium marker:content-none hover:cursor-pointer bg-profile-deposit border-profile-deposit border-2 rounded-lg">
            <summary class="flex items-center justify-between">
                <span v-if="props.icon" class="flex items-center gap-2">
                    <div class="text-[11px] flex justify-start items-center font-bold font-proxima">
                        <!-- <img
                            class="w-auto h-4 pr-3"
                            :class="props.icon == 'icon-game' ? 'filter' : ''"
                            :src="props.icon != 'icon-game'? require(`../assets/${props.icon}.png`) : require(`../assets/${props.icon}.svg`)"
                        /> -->
                        <div class="flex justify-start items-center pr-2">
                            <img class="w-4" :src="require(`@/assets/${props.icon}.png`)" alt="Icon" />
                        </div>
                        <div class="text-[#47516a] flex items-center pt-5 font-light h-4 transform">
                            |
                        </div>

                        <div class="flex h-full items-center">
                            <span class="text-white font-semibold pl-2">{{ text }} </span>
                        </div>

                        <!-- Adjust size as needed -->
                    </div>
                </span>
                <svg class="w-4 h-4 text-arrow-color transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                    width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                    </path>
                </svg>
            </summary>
            <ul class="list border-red-600 rounded-full flex gap-2 pl-2 mt-4">
                <li v-for="(item, index) in props.itemsList" :key="index"
                    class="gap-2 font-proxima text-dropdown-1-text-color text-xs bg-profile-deposit border-profile-deposit border-[3px] rounded-md px-3 py-1">
                    <button @click="chooseItem(item)">{{ item }}</button>
                </li>
            </ul>
        </details>
        <div class="dropdown-container  w-full" v-if="props.isDropDown == true && props.isCountry == true">
            <input :tabindex="index"
                class="srounded-lg py-[8px] w-full bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] rounded-md focus:ring-2"
                :type="props.type" :placeholder="placeHolder" @click="toggleDropdown" v-model="country_sel" />
            <div v-if="dropDownOpened" class="dropdown-menu bg-profile-deposit">
                <ul>
                    <li v-for="(country, index) in props.itemsList"
                        class="border-b-1 w-[full] text-xs border-profile-deposit" :key="index"
                        @click="selectCountry(country)">
                        {{ country }}
                    </li>
                </ul>
            </div>
            <div class="end-icon">
                <img v-if="green == true" src="../../assets/tick-green.svg" alt="Icon" width="24" height="24" />
                <button @click="toggleDropdown">
                    <img v-if="endIcon" :src="require(`../../assets/${endIcon}.svg`)" alt="Icon" width="20"
                        height="20" />
                </button>
                <!-- Adjust size as needed -->
            </div>
        </div>
    </div>
</template>

<script setup>

import { mount } from '@vue/test-utils';
import { onMounted, ref, watch } from 'vue'
const props = defineProps([
    'icon',
    'endIcon',
    'isDropDown',
    'isCountry',
    'itemsList',
    'type',
    'index',
    'green',
    'placeHolder',
    'required',
    'isSearch',
    'login',
    'profile',
    'noSpace',
    'amount',
    'toggleAction'
])


const text = ref('')
const dropDownOpened = ref(false)
const country_sel = ref('')
const text_ref = ref(null)
const error = ref(false)
const errorMessage = ref('')
const showPassword = ref(false)
const toggleDropdown = () => {
    dropDownOpened.value = !dropDownOpened.value;
}
const emit = defineEmits(['updateData', 'updateField'])
const selectCountry = (country) => {
    country_sel.value = country
    emit('updateField', { text: country })
    toggleDropdown()
}
const checkEmail = () => {
    console.log(text.value)
    if (text.value == '' && props.isSearch == false) {
        errorMessage.value = 'Field is required'
    }

    if (text.value != "") {
        error.value = !text_ref.value.checkValidity()
        console.log("updateUser", text_ref.value)
        emit('updateData', error.value)
        emit('updateField', { email: text_ref.value })
    } else emit("updateData", true)

}



const chooseItem = val => {
    text.value = val
    emit('updateField', { text: val })
}

const checkPassword = () => {
    const value = text_ref.value.value
    console.log("checking pass", text_ref.value.value)
    emit('updateField', { password: text_ref.value })
    if (value != "") {
        error.value = !text_ref.value.checkValidity()
        emit('updateData', error.value)
    }
    else if (props.required == true) {
        error.value = !text_ref.value.checkValidity()

        error.value = true;
        emit("updateData", error.value)
    } else if (value.length < 8 && props.login == false) {
        errorMessage.value = 'Password must be at least 8 characters long.'
        error.value = true
        emit('updateData', error.value)
    }
    else if (text_ref.value == '') {
        errorMessage.value = 'Field required'
    }
    else {
        error.value = false
        emit('updateData', error.value)
        errorMessage.value = ''
    }

}

const handleShowPassword = () => {
    showPassword.value = !showPassword.value
}
const handleChange = () => {
    if (props.type == 'text') {
        console.log("here")
        emit('updateField', { text: text.value })
    }
}
const handleBlur = () => {

    if (props.type == 'email' || props.type == "text") {
        return checkEmail()
    }
    if (props.type == 'password') {
        return checkPassword()
    }
}

watch(() => {
    if (props.amount) {
        text.value = props.amount
    }
})
const toggleFunction = () => {
    if (text.value == '')
        errorMessage.value = "Field  is  required"
    if (text_ref.value == '')
        errorMessage.value = 'Field  is required'
};
watch(
    () => props.toggleAction,
    (newVal) => {
        if (newVal) {
            toggleFunction();
        }
    }
);


</script>

<style scoped>
.input-container {
    position: relative;
    width: 100%;
}

input::placeholder {
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
        Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input {
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
        Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-container input {
    width: 100%;
    border-width: 2px;
    font-size: 16px;
    box-sizing: border-box;
}

.profile-container input {
    width: 100%;
    padding: 8px 20px 8px 25px;
    /* Adjust padding to make room for icon and pipe */
    border-width: 2px;
    font-size: 16px;
    box-sizing: border-box;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    max-height: 200px;
    /* Set a fixed height for scrollable area */
    overflow-y: auto;
    /* Enable vertical scrolling */
    width: 100%;
    /* Adjust width as needed */
}

.dropdown-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu li {
    padding: 2px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #374579;
}
.list li {
    margin: 5px 0;
}
li {
    list-style-type: none;
}
.transform {
    transform: translateY(-50%);
    font-size: 23px;
}
.input-container::before {
    position: absolute;
    left: 40px; /* Adjust position to place it right after the icon */
    top: 50%;
    transform: translateY(-50%);
    color: #47516a;
    font-size: 23px;
}

.input-container-2::before {
    left: 71px;
    top: 52%;
    transform: translateY(-49%);
    color: #47516a;
    font-size: 22px;
}

.end-icon {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
